.container {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 10%;
  left: 0%;
  transform-style: preserve-3d;
  animation: rotateCube 12s linear infinite;
}
.side {
  width: 90%;
  height: 90%;
  opacity: 0.7;
  position: absolute;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.front {
  transform: translateZ(100px);
}

.back {
  transform: translateZ(-100px);
}

.left {
  transform: rotateY(90deg) translateZ(100px);
}

.right {
  transform: rotateY(90deg) translateZ(-100px);
}

.top {
  transform: rotateX(90deg) translateZ(100px);
}

.bottom {
  transform: rotateX(90deg) translateZ(-100px);
}

@keyframes rotateCube {
  from {
    transform: rotateY(0deg) rotateX(360deg) rotateZ(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(0deg) rotateZ(360deg);
  }
}
